<template>
    <div class="services" id="service">
        <div class="services__container container">
            <div class="services__title">
                Услуги
            </div>
            <div class="services__list">
                <div class="services__list-item"
                     v-for="service in services"
                     :key="service.title"
                >
                    <img class="services__list-item-img" :src="service.preview"/>
                    <div class="services__list-item-name">
                        {{ service.title }}
                    </div>
                    <div class="services__list-item-price">
                        {{ service.price }}
                    </div>
                    <Btn
                        :class="['services__list-item-btn', {'ms_booking': service.button_code === 'training' || service.button_code === 'court'}]"
                        :data-url="service.button_code === 'training' ? 'https://n1079742.yclients.com/company/888903/activity/select?o=act2024-08-08' : 'https://n1079742.yclients.com/company/888903/personal/select-services?o=m2853130'"
                        :text="service.button_title"
                        @clickDef="buttonMove(service.button_code)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from "@/components/blocks/Btn";

export default {
    name: "SecondBlockServices",
    components: {Btn},
    props: {
        services: {}
    },
    methods: {
        buttonMove(move) {
            switch (move) {
                case 'court':
                    break
                case 'trial':
                    this.$emit('scrollTo', 'form')
                    break
                case 'event':
                    this.$emit('scrollTo', 'events')
            }
        }
    },
}
</script>

<style lang="scss">
.services {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    background: url('@/assets/img/blackBg.png') center/cover no-repeat;

    @include below($mobile) {
        margin: 10px 0;
    }

    &:after {
        position: absolute;
        content: '';
        top: -20px;
        width: 100%;
        height: 20px;
        background: linear-gradient(90deg, #4961F9 0%, #FF4747 49.33%, #FFB72A 100.76%);

        @include below($mobile) {
            top: -10px;
            width: 100%;
            height: 10px;
        }
    }


    &:before {
        position: absolute;
        content: '';
        bottom: -20px;
        width: 100%;
        height: 20px;
        background: linear-gradient(90deg, #4961F9 0%, #FF4747 49.33%, #FFB72A 100.76%);

        @include below($mobile) {
            bottom: -10px;
            width: 100%;
            height: 10px;
        }
    }

    &__container {
        padding: 130px 0 100px;

        @include below($mobile) {
            padding: 80px 0;
        }
    }

    &__title {
        display: flex;
        justify-content: center;
        font-family: BOWLER, sans-serif;
        font-size: 48px;
        font-weight: 400;

        @include below($mobile) {
            font-family: BOWLER, sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 50px;
        }
    }

    &__list {
        margin-top: 55px;
        display: flex;
        justify-content: space-between;

        @include below($tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 0;
            padding: 0 10px;
        }
    }

    &__list-item-img {
        max-width: 260px;
        border-radius: 300px;

        @include below($desktop) {
            max-width: 220px;
        }

        @include below($mobile) {
            max-width: 160px;
        }

        @include below($mobileS) {
            max-width: 120px;
        }
    }

    &__list-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include below($desktop) {
            margin-top: 40px;
        }
    }

    &__list-item-name {
        margin-top: 20px;
        font-family: BOWLER, sans-serif;
        font-size: 20px;
        font-weight: 400;

        @include below($mobile) {
            margin-top: 8px;
            font-size: 12px;
        }
    }

    &__list-item-price {
        margin-top: 15px;
        margin-bottom: 20px;
        font-family: Nunito, sans-serif;
        font-size: 22px;
        font-weight: 500;

        @include below($mobile) {
            margin: auto 0 10px;
            font-size: 14px;
            padding: 10px 0 0px;
        }
    }

    &__list-item-btn {
        margin-top: auto;

        &:hover {
            background: #FFC83A !important;
        }

        @include below($mobile) {
            padding: 14px 14px 11px !important;
            font-size: 12px !important;
            margin-top: auto !important;
        }
    }
}
</style>